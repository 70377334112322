"use client";

import Link from "next/link";
import { usePathname } from "next/navigation";

import { useAuth } from "@context/User";
import { cn } from "@libs/utils";

import { loggedInMenu } from "@configs/features/menus";

const Navbar = () => {
  const pathname = usePathname();
  const { contentfulPathwayId } = useAuth();

  if (pathname === "/") {
    return null;
  }

  return (
    <div className="hidden md:flex items-center gap-2 bg-[#f8f7f6] border rounded-full p-1">
      {loggedInMenu.map(item => {
        if (item.path === "/pathway" && !contentfulPathwayId) {
          return null;
        }

        const Icon = item.icon;

        return (
          <Link
            key={item.path}
            href={item.path}
            className={cn(
              "flex items-center justify-center gap-2 py-2 px-4 rounded-full hover:bg-[#f1f1f1] font-medium text-sm",
              { "text-primary bg-white": pathname === item.path }
            )}
          >
            <Icon /> <span className="text-black">{item.name}</span>
          </Link>
        );
      })}
    </div>
  );
};

export default Navbar;
