"use client";

import Image from "next/image";

import { usePopup } from "@context/PopupContext";

import { Button } from "@components/ui/button";

import PopupRequestDemo from "./PopupRequestDemo";

export const FirstFold = () => {
  const { onOpenPopup, onClosePopup } = usePopup();

  const handleRequestADemo = () => {
    onOpenPopup(<PopupRequestDemo onClose={onClosePopup} />);
  };

  return (
    <div className="container mx-auto mb-5">
      <div className="flex flex-col-reverse md:flex-row items-center justify-between">
        <div className="flex flex-col items-start gap-6 w-1/2">
          <h1 className="text-[40px]/none md:text-[65px]/none font-['frank-ruhl-libre'] text-[#4e3b30]">
            Develop your <span className="font-bold">frontline.</span> Improve your{" "}
            <span className="font-bold">bottom line.</span>
          </h1>
          <h2 className="text-xl text-[#4e3b30]">
            The Assemble platform transforms intelligence into personalized development and
            strategic workforce planning for your entire nursing organization.
          </h2>

          <Button
            variant="success"
            onClick={handleRequestADemo}
            className="text-success animated-button"
          >
            <span className="text-white">Request a demo</span>
          </Button>
        </div>

        <Image
          priority
          width={725}
          height={546}
          quality={100}
          alt="Come Assemble"
          src="/images/home/home-score-10.png"
        />
      </div>
    </div>
  );
};
