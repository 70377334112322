"use client";

import Image from "next/image";

import { TypeAnimation } from "react-type-animation";

import { Badge } from "@components/ui/badge";

export const ThirdFold = () => {
  return (
    <div className="flex flex-col md:flex-row justify-between items-center md:pt-[200px] pt-20 md:pb-20 pb-5">
      <div className="container mx-auto">
        <div className="flex flex-col items-start w-full md:w-1/2 gap-5">
          <Badge size="lg">
            <TypeAnimation
              sequence={[
                "Designed for frontline nurses",
                1000,
                "Designed for nurse leaders",
                1000,
                "Designed for your entire team",
                1000,
              ]}
              repeat={Infinity}
              preRenderFirstString
              deletionSpeed={50}
            />
          </Badge>
          <h2 className="text-[#fc6d1d] md:text-[40px]/[60px] text-[25px]/tight font-semibold font-['frank-ruhl-libre']">
            Assemble: A personalized experience{" "}
            <span className="text-[#382b23] md:text-[55px]">Just for you. Just enough.</span>
          </h2>
          <p className="text-[#382b23] text-[20px]">
            Individuals focus on professional development skills specific to their role and practice
            application for seamless integration into daily practice.
          </p>
        </div>
      </div>

      <Image
        src="/images/home/home-pathway-bg-.png"
        alt="Illustration"
        width={877}
        height={575}
        quality={90}
        className="w-[45%] absolute right-0 hidden md:block"
      />
    </div>
  );
};

export default ThirdFold;
