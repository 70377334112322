"use client";

import { useState } from "react";

import fetchClient from "@services/fetchClient";

type TransactionalEmailProps = {
  from: { email: string; name: string };
  subject: string;
  content?: string;
};

const useTransactionalEmail = () => {
  const [showSuccessPage, setShowSuccessPage] = useState<boolean>(false);

  const onSendEmail = async ({ content, from, subject }: TransactionalEmailProps) => {
    try {
      const params = { content, from, subject };
      const url = "/api/notifications/send-transactional-email";
      const response = await fetchClient.post(url, params);

      if (!response) {
        throw new Error("Error on sending email");
      }

      setShowSuccessPage(true);

      return response;
    } catch (error) {
      console.error(error);
    }
  };

  return { onSendEmail, showSuccessPage };
};

export default useTransactionalEmail;
