import { signOut } from "next-auth/react";
import type { ElementType } from "react";

import AskAssembleIcon from "public/images/menu/ask-assemble.svg";
import AssembleUIcon from "public/images/menu/assemble-u.svg";
import DiscoverIcon from "public/images/menu/discover.svg";
import HomeIcon from "public/images/menu/home.svg";

export type UserMenuItem = {
  href: string;
  label: string;
  onClick?: () => void;
};

export const userMenu: UserMenuItem[] = [
  { href: "/admin", label: "Admin" },
  { href: "/leader/dashboard", label: "Leader Dashboard" },
  { href: "/profile/general", label: "Profile" },
  {
    href: "/",
    label: "Logout",
    onClick: async () => signOut({ redirect: true, callbackUrl: "/" }),
  },
];

export type LoggedInMenuItem = {
  name: string;
  path: string;
  icon: ElementType;
};

export const loggedInMenu: LoggedInMenuItem[] = [
  { icon: HomeIcon, name: "Dashboard", path: "/dashboard" },
  { icon: AskAssembleIcon, name: "Ask Assemble", path: "/ask-assemble" },
  { icon: DiscoverIcon, name: "Discover", path: "/discover" },
  { icon: AssembleUIcon, name: "My Pathway", path: "/pathway" },
];
