"use client";

import Image from "next/image";

import { usePopup } from "@context/PopupContext";

import { Button } from "@components/ui/button";

import PopupRequestDemo from "./PopupRequestDemo";

export const FourthFold = () => {
  const { onOpenPopup, onClosePopup } = usePopup();

  const handleRequestADemo = () => {
    onOpenPopup(<PopupRequestDemo onClose={onClosePopup} />);
  };

  return (
    <div className="fourth-fold">
      <div className="container mx-auto mt-20">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="flex flex-col items-start w-full md:w-1/2 gap-10">
            <h2 className="text-[#767ee2] md:text-[45px]/tight text-[30px]/tight font-bold font-['frank-ruhl-libre']">
              Assemble supports your entire nursing organization
            </h2>
            <Button className="text-primary animated-button" onClick={handleRequestADemo}>
              <span className="text-white">Request a demo</span>
            </Button>
          </div>

          {/* SChart */}
          <div className="relative flex justify-between items-center h-[400px] md:h-[560px] w-[max-content] md:w-1/2 transform scale-[0.8] md:scale-100">
            {/* SChartWrapper */}
            <div className="relative md:absolute md:top-[50%] md:left-[50%] md:-translate-y-[50%] md:-translate-x-[50%]">
              {/* SOuterCircle */}
              <div className="relative h-[270px] md:h-[350px] w-[270px] md:w-[350px] border rounded-full transform-origin-center border-secondary-10 rotate-chart">
                <div className="chart-item -top-[15px] left-[123.54px] md:left-[173.54px]">
                  <span className="absolute text-center text-secondary -top-[50px] -left-[60px] rotate-chart direction-reverse">
                    Scalable Coaching
                  </span>
                </div>
                <div className="chart-item top-[48px] left-[234.71px] md:left-[294.71px]">
                  <span className="absolute text-center text-secondary -top-[30px] left-[35px] rotate-chart direction-reverse">
                    Certification Prep
                  </span>
                </div>
                <div className="chart-item top-[208px] left-[219.53px] md:left-[329.53px]">
                  <span className="absolute text-center text-secondary -top-[35px] left-[35px] rotate-chart direction-reverse">
                    People Analytics
                  </span>
                </div>
                <div className="chart-item top-[254.93px] md:top-[324.93px] left-[119.72px] md:left-[219.72px]">
                  <span className="absolute text-center text-secondary top-[30px] left-[20px] rotate-chart direction-reverse">
                    Annual Reviews
                  </span>
                </div>
                <div className="chart-item top-[200.08px] md:top-[270.08px] left-[25.73px] md:left-[10.73px]">
                  <span className="absolute text-center text-secondary top-[30px] -left-[60px] rotate-chart direction-reverse">
                    Succession Planning
                  </span>
                </div>
                <div className="chart-item top-[54.22px] md:top-[104.22px] -left-[3.26px]">
                  <span className="absolute text-center text-secondary -top-[45px] -left-[80px] rotate-chart direction-reverse">
                    Personalized Development
                  </span>
                </div>
              </div>
            </div>

            {/* SInnerCircle */}
            <div className="absolute flex justify-center items-center top-[50%] left-[50%] -translate-y-[50%] -translate-x-[50%] h-[200px] md:h-[250px] w-[200px] md:w-[250px] rounded-full bg-primary">
              <Image
                src="/images/come-assemble-logo-white.svg"
                alt="Come Assemble Logo"
                width={150}
                height={40}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
