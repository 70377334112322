import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["FirstFold"] */ "/vercel/path0/src/components/Homepage/FirstFold.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FourthFold"] */ "/vercel/path0/src/components/Homepage/FourthFold.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SecondFold"] */ "/vercel/path0/src/components/Homepage/SecondFold.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThirdFold"] */ "/vercel/path0/src/components/Homepage/ThirdFold.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/layout/Header/index.tsx");
