"use client";

import * as yup from "yup";

import { usaStates } from "@configs/locations/usaStates";

import { emailValidator } from "@helpers/custom.validators";

export const companyType = [
  "Primary health care / urgent care / clinic",
  "Healthcare system / acute care hospital",
  "Psychiatric hospital",
  "Post-acute health care / nursing home / hospice",
  "Ambulatory surgery center / medical group",
  "Home health care",
  "Outpatient programs / addiction treatment",
  "Recruiting or marketing agency",
  "Other",
];

export const validateSchema = yup
  .object()
  .shape({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    email: emailValidator,
    phone: yup.string().required("Phone number is required"),
    companyName: yup.string().required("Company name is required"),
    companyType: yup.string().required("Company type is required"),
    companyState: yup.string().required("Company state is required"),
  })
  .required();

export type FormValues = yup.InferType<typeof validateSchema>;

export const fields = [
  { name: "firstName", label: "First Name", fieldType: "input" },
  { name: "lastName", label: "Last Name", fieldType: "input" },
  { name: "email", label: "Email", fieldType: "input" },
  { name: "phone", label: "Phone", fieldType: "input" },
  {
    name: "companyName",
    label: "Company Name",
    fieldType: "input",
  },
  {
    name: "companyType",
    label: "Company Type",
    fieldType: "dropdown",
    placeholder: "Select a company type",
    options: companyType.map(type => ({ value: type, label: type })),
  },
  {
    name: "companyState",
    label: "Company State",
    fieldType: "dropdown",
    placeholder: "Select a state",
    options: usaStates.map(({ name, abbreviation }) => ({
      value: abbreviation,
      label: name,
    })),
  },
];

export function getMappedFields(values: FormValues) {
  return Object.entries(values)
    .map(([key, value]) => {
      const field = fields.find(({ name }) => name === key);

      return `<strong>${field?.label}</strong>: ${value}`;
    })
    .join("<br />");
}
