import dayjs from "dayjs";
import type { User } from "next-auth";
import * as yup from "yup";

import { dateValidator, phoneNumberValidator } from "@helpers/custom.validators";

import {
  confidentSkills,
  gender,
  goalsSkills,
  race,
  specialties,
  strengthSkills,
} from "./profileSkills";

export const REQUIRED_TEXT = "This is a required field";

export type ProfileFormType = {
  title: string;
  fields: {
    id: string;
    label: string;
    type: string;
    options?: { value: string; label: string }[];
    validation: yup.AnySchema;
    value: unknown;
    disabled?: boolean;
  }[];
}[];

export function getProfileForm(user: User, module: string): ProfileFormType {
  const { name, profile, organization } = user;

  const modules = {
    general: [
      {
        title: "Personal Information",
        fields: [
          {
            label: "Name",
            id: "name",
            type: "input",
            validation: yup.string().required(REQUIRED_TEXT),
            value: name,
          },
          {
            label: "Phone",
            id: "phone",
            type: "phone",
            validation: phoneNumberValidator,
            value: profile?.phone,
          },
          {
            label: "Gender",
            id: "gender",
            type: "dropdown",
            options: gender.map(type => ({ value: type, label: type })),
            validation: yup.string().required(REQUIRED_TEXT),
            value: profile?.gender,
          },
          {
            label: "Race",
            id: "race",
            type: "dropdown",
            options: race.map(type => ({ value: type, label: type })),
            validation: yup.string().required(REQUIRED_TEXT),
            value: profile?.race,
          },
        ],
      },
      {
        title: "Organization",
        fields: [
          {
            label: "Role",
            id: "roleId",
            type: "dropdown",
            options: organization?.roles?.map(({ id, role }) => ({
              value: String(id),
              label: role,
            })),
            validation: yup.string().required(REQUIRED_TEXT),
            value: String(profile?.roleId),
          },
          {
            label: "Years of Experience",
            id: "yearsExperience",
            type: "input",
            validation: yup.string().required(REQUIRED_TEXT),
            value: profile?.yearsExperience,
          },
          {
            label: "Clinical Care Area/Clinical Support",
            id: "unitId",
            type: "dropdown",
            options: organization?.units.map(({ id, title }) => ({
              value: String(id),
              label: title,
            })),
            validation: yup.string().required(REQUIRED_TEXT),
            value: String(profile?.unitId),
          },
          {
            label: "Specialty",
            id: "specialty",
            type: "dropdown",
            options: specialties?.map(type => ({ value: type, label: type })),
            validation: yup.string().required(REQUIRED_TEXT),
            value: profile?.specialty,
          },
        ],
      },
    ],
    goals: [
      {
        title: "Professional Goals",
        fields: [
          {
            label: "What are your professional aspirations for the next 5 years",
            id: "professionalGoals",
            type: "chip",
            options: goalsSkills.map(type => ({ value: type, label: type })),
            validation: yup
              .array()
              .min(2, "Field must have at least 2 items")
              .max(3, "Field must have at most 3 items")
              .required(REQUIRED_TEXT)
              .nullable(),
            value: profile?.professionalGoals ?? [],
          },
        ],
      },
    ],
    strengths: [
      {
        title: "Skills",
        fields: [
          {
            id: "leastConfident",
            type: "chip",
            label: "I am least confident in my abilities as it relates to:",
            options: confidentSkills.map(type => ({ value: type, label: type })),
            validation: yup
              .array()
              .min(2, "Field must have at least 2 items")
              .max(3, "Field must have at most 3 items")
              .required(REQUIRED_TEXT)
              .nullable(),
            value: profile?.leastConfident ?? [],
          },
          {
            id: "strengthsToImprove",
            type: "chip",
            label: "My goals for development are focused on:",
            options: strengthSkills.map(type => ({ value: type, label: type })),
            validation: yup
              .array()
              .min(2, "Field must have at least 2 items")
              .max(3, "Field must have at most 3 items")
              .required(REQUIRED_TEXT)
              .nullable(),
            value: profile?.strengthsToImprove ?? [],
          },
        ],
      },
    ],
    education: [
      {
        title: "More",
        fields: [
          {
            label: "Committees",
            id: "committees",
            type: "dropdown",
            validation: yup.array(),
            options:
              organization?.committees?.map(({ id, title }) => ({
                value: String(id),
                label: title,
              })) ?? [],
            value: profile?.committees?.map(({ id }) => id) ?? [],
            multiple: true,
          },
          {
            label: "Shared Governance",
            id: "sharedGovernance",
            type: "radio",
            validation: yup.boolean(),
            value: profile?.sharedGovernance,
            options: [
              { value: "true", label: "Yes" },
              { value: "false", label: "No" },
            ],
          },
          {
            label: "Charge Nurse",
            id: "chargeNurse",
            type: "date",
            validation: dateValidator,
            value: profile?.chargeNurse
              ? dayjs(profile?.chargeNurse).toISOString()?.substr(0, 10)
              : "",
          },
          {
            label: "Preceptor",
            id: "preceptor",
            type: "date",
            validation: dateValidator,
            value: profile?.preceptor ? dayjs(profile?.preceptor).toISOString()?.substr(0, 10) : "",
          },
        ],
      },
    ],
  };

  return modules[module as keyof typeof modules];
}
