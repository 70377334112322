export const askAssembleSelectors = {
  history: "ask-assemble-history",
  inputForm: "ask-assemble-input-form",
  makeItSimpler: "ask-assemble-make-it-simpler",
  name: "ask-assemble-name",
  newChat: "ask-assemble-new-chat",
  emptyChat: "ask-assemble-empty-chat",
  promptLibraryBtn: "ask-assemble-promptLibraryBtn",
  prompt: "ask-assemble-prompt",
  question: "ask-assemble-question",
  response: "ask-assemble-response",
  submit: "ask-assemble-submit",
  tellMeMore: "ask-assemble-tell-me-more",
  topicSelector: "ask-assemble-topic-selector",
  shareBtn: "ask-assemble-shareBtn",
  openShareModalBtn: "ask-assemble-openShareModalBtn",
};

export const assembleUSelectors = {
  resource: "assemble-u-resource",
  toolkit: "assemble-u-toolkit",
};

export const confirmationPopupSelectors = {
  modal: "confirmation-popup-modal",
  confirm: "confirmation-popup-confirm",
};

export const dropdownMenuSelectors = {
  button: "dropdown-menu-button",
  menuItem: (item?: string) => (item ? `dropdown-menu-item-${item}` : "dropdown-menu-item"),
};

export const signupSelectors = {
  pageTitle: "register-page-title",
  password: "password-field",
  email: "email-field",
  passwordConfirmation: "password-confirmation-field",
  createAccountBtn: "create-account-btn",
};

export const pageSelectors = {
  title: "page-title",
};

export const tabsSelectors = {
  item: "tabs-item",
};

export const discoverSelectors = {
  featuredPost: "discover-featured-post",
  postCard: "discover-post-card",
  latestPosts: "discover-latest-posts",
  filterCategories: "discover-filter-categories",
  filterFormats: "discover-filter-formats",
  postCardCategory: "discover-post-card-category",
  postCardFormat: "discover-post-card-format",
};

export const signinSelectors = {
  loginBtn: "login-btn",
  password: "password-field",
  email: "email-field",
  submitBtn: "sign-in-btn",
};

export const listSelectors = {
  item: (item: string) => `list-item-${item}`,
};

export const onboardingSelectors = {
  name: "name",
  role: "role",
  yearsExperience: "yearsExperience",
  phoneNumber: "onboarding-phoneNumber",
  phoneNumberRequestCode: "onboarding-phoneNumberRequestCode",
  smsCode: "onboarding-smsCode",
  smsCodeConfirm: "onboarding-smsCodeConfirm",
  managerId: "managerId",
  unitId: "unitId",
  campusId: "campusId",
  specialty: "specialty",
  nextStep: "onboarding-nextStep",
  prevStep: "onboarding-prevStep",
};

export const selectDropdownSelectors = {
  option: (value: string) => `selectDropdown-option-${value}`,
};

export const unauthPromptSelectors = {
  title: "unauth-prompt-title",
};

export const askAssembleMarketingSelectors = {
  category: `askAssembleMarketing-category`,
  question: `askAssembleMarketing-question`,
  register: `askAssembleMarketing-register`,
};

export const profileSelectors = {
  editMode: "edit-mode",
  viewMode: "view-mode",
  editProfileBtn: "edit-profile-btn",
  profileFieldGivenName: "profile-field-givenName",
  profileFieldFamilyName: "profile-field-familyName",
  profileFieldName: "profile-field-name",
  saveBtn: "save-btn",
  uploadImageBtn: "upload-image-btn",
  saveAvatarBtn: "save-avatar-btn",
};
