"use client";

import dynamic from "next/dynamic";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";

import { usePopup } from "@context/PopupContext";
import { useAuth } from "@context/User";
import { cn } from "@libs/utils";

import type { LoginPopupProps } from "@components/account/Login/Popup";
import { Button } from "@components/ui/button";

import { signinSelectors } from "@configs/features/testSelectors";

import Navbar from "../Navbar";
import UserMenu from "../UserMenu";

import HeaderSkeleton from "./Skeleton";

const LoginPopup = dynamic<LoginPopupProps>(() => import("@components/account/Login/Popup"), {
  ssr: false,
});

const Header = ({ className }: { className?: string }) => {
  const user = useAuth();
  const pathname = usePathname();
  const { onOpenPopup, onClosePopup } = usePopup();

  const handleLogin = () => {
    onOpenPopup(<LoginPopup onClose={onClosePopup} />);
  };

  const navbarContent = () => {
    if (user.isLoading) {
      return <HeaderSkeleton />;
    }

    if (!user.id) {
      return (
        <Button onClick={handleLogin} data-testid={signinSelectors.loginBtn}>
          Login
        </Button>
      );
    }

    return (
      <>
        <Navbar />
        <div className="flex items-center gap-3">
          {pathname === "/" && (
            <Button asChild size="sm">
              <Link href="/dashboard">Access Assemble</Link>
            </Button>
          )}
          <UserMenu {...user} />
        </div>
      </>
    );
  };

  return (
    <header className={cn("py-5", className)}>
      <div className="container mx-auto">
        <div className="flex justify-between items-center">
          <Link href={user.id ? "/dashboard" : "/"}>
            <Image src="/images/come-assemble-logo.svg" alt="Logo" width={135} height={26} />
          </Link>

          {navbarContent()}
        </div>
      </div>
    </header>
  );
};

export default Header;
