"use client";

import Image from "next/image";

import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { Button } from "@components/ui/button";

type SuccessMessagePopupProps = {
  title?: string;
  message?: string;
  image?: string;
  buttonLabel?: string;
  onClose: () => void;
};

const SuccessMessagePopup = ({
  title = "Thanks for reaching out!",
  message = "We’ll be in touch over email as soon as possible.",
  image = "/images/popup-success.svg",
  buttonLabel = "OK",
  onClose,
}: SuccessMessagePopupProps) => {
  return (
    <Stack direction="column" spacing={3} alignItems="center" p={{ xs: 0, lg: 3 }}>
      <Box sx={{ position: "relative", width: "100%", height: { lg: 255, xs: 200 } }}>
        <Image src={image} alt="Success" fill sizes="100vw" />
      </Box>
      <Stack direction="column" spacing={1} alignItems="center">
        <Typography variant="title" sx={{ fontSize: { xs: 28, lg: 34 }, textAlign: "center" }}>
          {title}
        </Typography>
        <Typography sx={{ textAlign: "center", fontSize: { xs: 14, lg: 16 } }}>
          {message}
        </Typography>
      </Stack>
      <Button onClick={onClose}>{buttonLabel}</Button>
    </Stack>
  );
};

export default SuccessMessagePopup;
