"use client";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import SuccessMessagePopup from "@components/common/SuccessMessagePopup";
import { SurveyStepQuestion } from "@components/features/Survey/Question";
import { Button } from "@components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@components/ui/dialog";

import useTransactionalEmail from "@hooks/useTransactionalEmail";
import { TypeSurveyQuestionFields } from "@models/Contentful";

import { FormValues, fields, getMappedFields, validateSchema } from "./helper";

type PopupRequestDemoProps = {
  onClose: () => void;
};

const PopupRequestDemo = ({ onClose }: PopupRequestDemoProps) => {
  const { onSendEmail, showSuccessPage } = useTransactionalEmail();

  const { handleSubmit, control, formState } = useForm<FormValues>({
    resolver: yupResolver(validateSchema),
    defaultValues: fields.map(({ name }) => ({ [name]: "" })),
  });

  const onSubmit = async (values: FormValues) => {
    try {
      const content = getMappedFields(values);

      await onSendEmail({
        content,
        from: { email: values.email, name: values.firstName },
        subject: "Request a demo - New register",
      });
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <Dialog open modal onOpenChange={onClose}>
      <DialogContent>
        {showSuccessPage ? (
          <SuccessMessagePopup onClose={onClose} />
        ) : (
          <>
            <DialogHeader>
              <DialogTitle className="mb-3 text-xl">Request a demo</DialogTitle>
            </DialogHeader>

            <form className="grid grid-cols-2 gap-4">
              {fields.map(({ name, fieldType, label, ...rest }) => (
                <SurveyStepQuestion
                  key={name}
                  control={control}
                  question={{
                    id: name,
                    name,
                    label,
                    type: fieldType as TypeSurveyQuestionFields["type"],
                    ...rest,
                  }}
                />
              ))}
            </form>

            <Button isLoading={formState.isLoading} onClick={handleSubmit(onSubmit)}>
              Request a demo
            </Button>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default PopupRequestDemo;
