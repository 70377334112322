"use client";

import { ReactNode, createContext, useContext, useState } from "react";

type PopupProps = ReactNode;

type PopupContextType = {
  onOpenPopup: (data: PopupProps) => void;
  onClosePopup: () => void;
};

const PopupContext = createContext({} as PopupContextType);

export default function PopupProvider({ children }: { children: React.ReactNode }) {
  const [popup, setPopup] = useState<PopupProps>(undefined);

  const onOpenPopup = (data: PopupProps) => setPopup(data);
  const onClosePopup = () => setPopup(undefined);

  return (
    <PopupContext.Provider value={{ onOpenPopup, onClosePopup }}>
      {popup}
      {children}
    </PopupContext.Provider>
  );
}

export const usePopup = () => useContext<PopupContextType>(PopupContext);
