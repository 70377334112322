import { Skeleton } from "@mui/material";

const HeaderSkeleton = () => {
  return (
    <>
      <Skeleton
        animation="wave"
        width={650}
        height={60}
        variant="rounded"
        sx={{ borderRadius: 48 }}
        className="hidden md:block"
      />
      <Skeleton animation="wave" width={60} height={60} variant="circular" />
    </>
  );
};

export default HeaderSkeleton;
