"use client";

import { useSession } from "next-auth/react";
import { usePostHog } from "posthog-js/react";
import { ReactNode, createContext, useContext, useEffect } from "react";

import { UserType } from "@prisma/client";
import * as Sentry from "@sentry/browser";
import { User } from "next-auth";

import { getInitialsFromName } from "@helpers/profile";
import { useProfile } from "@hooks/useProfile";

export type UserContextType = User & {
  userId?: string;
  isAuthenticated: boolean;
  isUnauthenticated: boolean;
  isLoading: boolean;
  isAdmin: boolean;
  initials: string;
  givenName?: string;
};

export const UserContext = createContext<UserContextType>({} as UserContextType);

const UserProvider = ({ children }: { children: ReactNode }) => {
  const posthog = usePostHog();
  const { data, status } = useSession();
  const { data: user } = useProfile(data?.user as User);

  const userId = user?.id;
  const isLoading = status === "loading";
  const isAuthenticated = status === "authenticated";
  const isUnauthenticated = status === "unauthenticated";
  const initials = getInitialsFromName(user?.name as string);
  const givenName = user?.name?.split(" ")[0];
  const isAdmin = user?.roles?.includes(UserType.ADMIN);

  useEffect(() => {
    if (user) {
      const { id, ...userData } = user;
      posthog.identify(id, userData);
      Sentry.setUser({ id, email: userData.email, username: userData.name });
    }
  }, [user]);

  return (
    <UserContext.Provider
      value={{
        ...user,
        userId,
        isLoading,
        isAuthenticated,
        isUnauthenticated,
        initials,
        givenName,
        isAdmin,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;

export const useAuth = () => useContext<UserContextType>(UserContext);
