import { ChangeEvent, FocusEvent, forwardRef } from "react";

import { SurveyQuestion } from "@/types/survey";

import { Checkbox } from "@components/ui/checkbox";
import { Input } from "@components/ui/input";
import { RadioGroup, RadioGroupItem } from "@components/ui/radio-group";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@components/ui/select";

type FieldProps = SurveyQuestion & {
  onChange: (event: ChangeEvent<HTMLElement> | string | string[]) => void;
  onBlur: (event: FocusEvent<HTMLElement>) => void;
  value: any;
};

export const TextField = forwardRef((question: FieldProps, ref) => {
  return <Input {...question} />;
});

export const RadioField = forwardRef(
  ({ name, options, onChange, onBlur, value }: FieldProps, ref) => {
    const fieldOptions = getOptions(options);

    return (
      <RadioGroup
        onBlur={onBlur}
        value={value as string}
        onValueChange={onChange}
        className="flex flex-col md:flex-row flex-wrap gap-5"
      >
        {fieldOptions?.map(option => (
          <div className="flex items-center space-x-2" key={`radio_${name}_${option.value}`}>
            <RadioGroupItem value={option.value as any} id={`radio_${name}_${option.value}`} />
            <label htmlFor={`radio_${name}_${option.value}`}>{option.label}</label>
          </div>
        ))}
      </RadioGroup>
    );
  }
);

export const CheckboxField = forwardRef(({ name, options, value, onChange }: FieldProps, ref) => {
  const fieldOptions = getOptions(options);

  const handleChangeCheckbox = (selectedOption: string) => {
    const valueSet = new Set(value as string[]);

    if (valueSet.has(selectedOption)) {
      valueSet.delete(selectedOption);
    } else {
      valueSet.add(selectedOption);
    }

    onChange(Array.from(valueSet));
  };

  return (
    <div className="flex flex-col md:flex-row flex-wrap gap-5">
      {fieldOptions?.map(option => (
        <div className="flex items-center space-x-2" key={`checkbox_${name}_${option.value}`}>
          <Checkbox
            name={name}
            id={`checkbox_${name}_${option.value}`}
            onCheckedChange={() => handleChangeCheckbox(option.value as string)}
          />
          <label htmlFor={`checkbox_${name}_${option.value}`}>{option.label}</label>
        </div>
      ))}
    </div>
  );
});

export const SelectField = forwardRef(
  ({ name, options, onChange, value, placeholder = "Select an option" }: FieldProps, ref) => {
    const fieldOptions = getOptions(options);

    return (
      <Select name={name} onValueChange={onChange} value={value}>
        <SelectTrigger>
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectLabel>{placeholder}</SelectLabel>
            {fieldOptions?.map(option => (
              <SelectItem key={`select_${name}_${option.value}`} value={option.value as string}>
                {option.label}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    );
  }
);

// ==============================================
function getOptions(options: SurveyQuestion["options"]) {
  return options?.map(option => {
    const isObject = typeof option === "object";

    return {
      value: isObject ? option.value : option,
      label: isObject ? option.label : option,
    };
  });
}
